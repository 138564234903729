<template>
  <ModuleForm>
    <template #form-title>
      {{ $t('oneSignal.addSite') }}
    </template>
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$tc('buttons.save')"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="existingDetail"
        :record-id="oneSignal.siteUuid"
        action-id-param="siteUuid"
        store-name="oneSignal"
        list-router-path="oneSignal"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/oneSignal')"
       />
    </template>
    <template #left-column>
      <Select
        v-model="oneSignal.siteUuid"
        :options="sites"
        @blur="$v.oneSignal.siteUuid.$touch()"
        :error="$v.oneSignal.siteUuid.$error"
        id="oneSignal_sites"
        :label="$tc('oneSignal.siteName')"
        :disabled="disabled"
      />
      <Input
        type="number"
        v-model="oneSignal.limit"
        @blur="$v.oneSignal.limit.$touch()"
        :error="$v.oneSignal.limit.$error"
        :step="1"
        :min="0"
        :max="9999"
        id="oneSignal_limit"
        :label="$tc('oneSignal.limit')"
        :placeholder="$tc('oneSignal.limit_placeholder')"
        :disabled="disabled"
      />
      <OutlinedCheckbox
        v-model="oneSignal.active"
        id="oneSignal_active"
        :label="$tc('oneSignal.active')"
        :disabled="disabled"
        no-label-margin
      />
    </template>
    <template #right-column>
      <Input
        v-model="oneSignal.appId"
        id="oneSignal_appId"
        :label="$tc('oneSignal.appId')"
        :disabled="disabled"
      />
      <Input
        v-model="oneSignal.apiKey"
        id="oneSignal_apiKey"
        :label="$tc('oneSignal.apiKey')"
        :disabled="disabled"
      />
      <Input
        v-model="oneSignal.utm"
        id="oneSignal_utm"
        :label="$tc('oneSignal.utm')"
        :disabled="disabled"
      />
    </template>
  </ModuleForm>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import OneSignalModel from '@/model/OneSignalModel'
import NotifyService from '@/services/NotifyService'
import ModuleForm from '@/components/shared/ModuleForm'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import Select from '@/components/form/select/Select'
import OneSignalApi from '@/api/oneSignal'

export default {
  name: 'OneSignalNewView',
  data () {
    return {
      oneSignal: this._.cloneDeep(OneSignalModel)
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    oneSignal: {
      siteUuid: {
        required
      },
      limit: {
        required
      }
    }
  },
  components: {
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    ModuleForm,
    OutlinedCheckbox,
    Select
  },
  computed: {
    sites () {
      return this.$store.getters['site/all']
    },
    existingDetail () {
      return this.$store.getters['oneSignal/detail']
    }
  },
  methods: {
    async save () {
      try {
        this.$v.$touch()
        if (this.$v.$invalid) {
          NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
          return
        }
        const url = `api/onesignal/settings/${this.oneSignal.siteUuid}`
        await OneSignalApi().put(url, this.oneSignal)
        NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
        this.goToEdit()
      } catch (error) {
        console.log(error)
        NotifyService.setErrorMessage(error)
      }
    },
    goToEdit () {
      this.$router.push('/oneSignal/' + this.oneSignal.siteUuid + '/edit')
    }
  }
}
</script>
